import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
// import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgFor, NgIf } from '@angular/common';
declare var $: any;
import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';


@Component({
  selector: 'product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss'],
  // providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class ProductCategoriesComponent implements OnInit {
  categories: Observable<any>;
  showNavigationArrows = false;
	showNavigationIndicators = false;
	images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  size: SCREEN_SIZE;

  constructor(
    public db: AngularFireDatabase,
    private title: Title,
    private meta: Meta,
    // private metaService: Meta,
    private resizeSvc: ResizeService
    // config: NgbCarouselConfig
  ) {
    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });
    // this.metaService.updateTag({
    //   name: 'viewport',
    //   content: 'width=device-width, initial-scale=0.2'
    // }, 'name=viewport');
   
    // this.categories = db.list('/categories', ref => ref.orderByChild('weight').limitToLast(999)).valueChanges();
    this.categories = this.db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(false)).valueChanges();
    // customize default values of carousels used by this component tree
		// config.showNavigationArrows = false;
		// config.showNavigationIndicators = false;
 
  }

  ngOnInit() {
    this.title.setTitle('Nowa Architects');
    this.meta.updateTag({ content: 'Nowa Architects' }, "name='description'");
    $(document).ready(function() {
      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });
  });
  }

  getCategoryImage(category:any) {
    if (category && category.thumbnail) {
      return category.thumbnail;
    } else {
      return '../../assets/placeholder.jpg';
    }
  }

}
