import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
declare var $: any;
import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  size: SCREEN_SIZE;
  constructor(private resizeSvc: ResizeService) { 
    // this.resizeSvc.onResize$
    // .pipe(delay(100))
    // .subscribe(x => {
    //   this.size = x;
    // });
  }

  ngOnInit(): void {
    // this.title.setTitle('Products');
    // this.meta.updateTag({ content: 'View all products' }, "name='description'");
    $(document).ready(function() {
      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });
    });

    // document.body.style.zoom = '50%';
    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });
  }
 

}
