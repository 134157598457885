import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
declare var $: any;

import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';

@Component({
  selector: 'app-introduce',
  templateUrl: './introduce.component.html',
  styleUrls: ['./introduce.component.scss']
})
export class IntroduceComponent implements OnInit {
  size: SCREEN_SIZE;
  constructor(private resizeSvc: ResizeService) {
    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });

   }

  ngOnInit(): void {
    // this.title.setTitle('Products');
    // this.meta.updateTag({ content: 'View all products' }, "name='description'");
    $(document).ready(function() {
        // Full-screen navigation
        var $offcanvasToggle = $('.offcanvas-toggle');
        var $offcanvas = $('.offcanvas');
        $offcanvasToggle.on('click', function () {
          $offcanvas.toggleClass('offcanvas--active');
          $offcanvasToggle.toggleClass('offcanvas-toggle--active');
        });
    });
    // this.resizeSvc.onResize$
    // .pipe(delay(10))
    // .subscribe(x => {
    //   this.size = x;
    // });
  }

}
