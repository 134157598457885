import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers,
 
})
export class HomeComponent implements OnInit {
  categories: Observable<any[]>;
  showNavigationArrows = false;
	showNavigationIndicators = false;
	images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  size: SCREEN_SIZE;
  scrHeight:any;
  scrWidth:any;


  // @HostListener('window:resize', ['$event'])
  // getScreenSize(event?) {
  //       this.scrHeight =0;
  //       this.scrWidth =0;
  //       this.scrHeight = window.innerHeight;
  //       this.scrWidth = window.innerWidth;
  //       console.log(this.scrHeight, this.scrWidth);        
  //         this.metaService.updateTag({
  //           name: 'viewport',
  //           content: 'width=device-width, initial-scale=1'
  //         }, 'name=viewport');
  // }

  constructor(
    public db: AngularFireDatabase,
    private title: Title,
    private meta: Meta,
    config: NgbCarouselConfig,
    private responsive: BreakpointObserver,
    private resizeSvc: ResizeService,
    private metaService: Meta
  ) {
    
    // this.metaService.updateTag({
    //   name: 'viewport',
    //   content: 'width=device-width, initial-scale=1'
    // }, 'name=viewport');

    // this.getScreenSize();

    // this.resizeSvc.onResize$
    // .pipe(delay(100))
    // .subscribe(x => {
    //   this.size = x;
    // });
    // this.images[0]='/assets/TEN/VAN PHU 3.jpg';

    // this.categories = db.list('categories').snapshotChanges();
    this.categories = this.db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(false)).valueChanges();
    // this.categories = db.list('/categories', ref => ref.orderByChild('weight').limitToLast(50)).valueChanges();
    // customize default values of carousels used by this component tree
		config.showNavigationArrows = false;
		config.showNavigationIndicators = false;

 
  }

  ngOnInit() {
    this.title.setTitle('Nowa Architects');
    this.meta.updateTag({ content: 'Nowa Architects' }, "name='description'");
  }
  

  getCategoryImage(category:any) {
    if (category && category.thumbnail) {
      return category.thumbnail;
    } else {
      return '../../assets/placeholder.jpg';
    }
  }

  

}
