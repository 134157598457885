

	<style>
    .gcat-item{
       float: left;
      height: 364px;
      position: relative;
      width: 298px;
      padding-left : 2px;
      padding-bottom: 2px;
      overflow: hidden;
    }
    .gcat-item img{
      width: 100%;
      height: 100%;
    }
    .gcat-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 183px;
      line-height: 20px;
      padding-top: 150px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 298px;
      display: none;
    }
    .gcat-item a.gcat-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .gcat-item:hover a.gcat-item-title{
      display: block;
      color:#fff;
    }

    .menu-item{
       float: left;
      height: 212px;
      position: relative;
      width: 139px;
      padding-left : 2px;
      padding-bottom: 2px;
      overflow: hidden;
    }
    .menu-item img{
      width: 100%;
      height: 100%;
    }
    .menu-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 212px;
      line-height: 20px;
      padding-top: 50px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 139px;
      display: none;
    }
    .menu-item a.menu-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .menu-item:hover a.menu-item-title{
      display: block;
      color:#fff;
    }
  </style>
  
  
  <div style="position: absolute;
    right: 580px;
    top: 0px;  ">
    <div class="offcanvas">
      <ul id="menu-vietnamese-menu" class="main-nav">
        <li>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <!-- <a href="/" itemprop="name">
              TRANG CHỦ            
            </a>
            <a class="menu-item-title" href="/" style="height: 141px;">TRANG CHỦ</a> -->
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="DỰ ÁN" itemprop="image">    -->
              DỰ ÁN           
            </a>
            <a class="menu-item-title" href="/products" style="height: 141px;">DỰ ÁN</a>
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="TRANG CHỦ" itemprop="image">   -->
              CHÚNG TÔI
            </a>
            <a class="menu-item-title" href="/introduce" style="height: 141px;">CHÚNG TÔI</a>
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="DỰ ÁN" itemprop="image">               -->
              NGHỆ THUẬT
            </a>
            <a class="menu-item-title" href="/nghethuat" style="height: 141px;">NGHỆ THUẬT</a>
          </div>
          
        </li>
        <!-- <li id="menu-item-54" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-547"><a
            href="/">TRANG CHỦ</a></li>
        <li id="menu-item-547" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-547"><a
            href="/products">DỰ ÁN</a></li>
        <li id="menu-item-546" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-546"><a
            href="/introduce">CHÚNG TÔI</a></li>					
        <li id="menu-item-548" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-548"><a
            href="/contact">LIÊN HỆ</a></li> -->
      </ul>
    </div>
    <button class="offcanvas-toggle">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
  
  
  <div class="logo">
    <a href="/"><img src="/assets/Home_files/logo.png" title="Trang chủ"></a>

  </div>
  
  <div class="main-component">
    <div id="system-message-container">
    </div>

        <div class="gcat-item" style="height: 300px;width: 298px;" *ngFor="let category of categories | async">
          <a href="/category/{{category.slug}}" itemprop="name">
            <img [src]="getCategoryImage(category)" title="{{category.name}}" itemprop="image">
            
          </a>
          <a class="gcat-item-title" href="/category/{{category.slug}}" style="height: 300px;"></a>
        </div>
        
    </div>
  <div class="body-gioithieu">
    
  </div>
  
  <script type="text/javascript">
    (function ($) {
      'use strict';

      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });

    }(jQuery));
  </script>
  







<!-- <div class="logo">
  <a href="/"><img src="/assets/Home_files/logo.png" title="Trang chủ"></a>
</div>

<div class="gioithieu">
  <div class="custom">
    <p><a href="/introduce.html" rel="alternate"><img src="/assets/Home_files/gioithieu.jpg" alt="giới thiệu"
          title="Giới thiệu công ty" width="150" height="150"></a></p>
  </div>
</div>

<div class="lienhe">
  <div class="custom">
    <p><a href="/contact.html" rel="alternate"><img src="/assets/Home_files/lienhe.jpg" alt="Liên hệ"
          title="Liên hệ công ty" width="75" height="75"></a></p>
  </div>
</div>

<div class="duan">
  <div class="slide-1">
    <div class="rslides rslides1">
      <ngb-carousel>
        <ng-template ngbSlide *ngFor="let category of categories | async">
          <div class="picsum-img-wrapper">
            <a href="/category/{{category.slug}}"> 
               <img style="width:300px;height:300px;" title="Các dự án" [src]="getCategoryImage(category)" alt="{{category.name}}"/>
             </a>
          </div>
         
        </ng-template>
      </ngb-carousel>
    </div> -->

    <!-- <ul class="rslides rslides1"> -->
      <!-- <li id="rslides1_s13" class="rslides1_on" style="display: list-item; float: left; position: relative; opacity: 1; z-index: 2; transition: opacity 500ms ease-in-out 0s;">
        <a href="/projects.html"><img style="width:300px;height:300px;" title="Các dự án" alt="tin tuc" src="/assets/TEN/D10.jpg"></a>
      </li> -->
     
      
      <!-- <li *ngFor="let category of categories | async" id="rslides1_s1" class="rslides1_on"
        style="display: list-item; float: none; position: absolute; opacity: 0; z-index: 1; -webkit-transition: opacity 500ms ease-in-out; transition: opacity 500ms ease-in-out;">
        <a href="/category/{{ category.slug }}"><img style="width:300px;height:300px;" title="Các dự án"
            [src]="getCategoryImage(category)" itemprop="image" alt="{{category.name}}"></a>
      </li>
      <li *ngFor="let category of categories | async" id="rslides1_s2" 
        style="display: list-item; float: none; position: absolute; opacity: 0; z-index: 1; -webkit-transition: opacity 500ms ease-in-out; transition: opacity 500ms ease-in-out;">
        <a href="/category/{{ category.slug }}"><img style="width:300px;height:300px;" title="Các dự án"
            [src]="getCategoryImage(category)" itemprop="image" alt="{{category.name}}"></a>
      </li>
    </ul> -->
  <!-- </div>

</div> -->
