export const environment = {
    production: true,
    firebase: {
      apiKey: "AIzaSyCIQv49KQ762OfgT56YT79ZlHhg7IPoN9E",
      authDomain: "nowa-9088e.firebaseapp.com",
      databaseURL: "https://nowa-9088e-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "nowa-9088e",
      storageBucket: "nowa-9088e.appspot.com",
      messagingSenderId: "1077604393230",
      appId: "1:1077604393230:web:b6b540dd9a30d95e5cf75f",
      measurementId: "G-BJTWQGYL57"
    },
    stripe: "pk_test_51JkOiXLiaOkf5okDTOmFMnN5U3NqvyFQXPIPapJGcnoPEY5lD2oCdFOTNXvTkiCbTXLHIeAbVIM207RBNwvC37Mv00RrMOVozm"
  };