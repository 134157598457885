
	<style>
    .gcat-item{
       float: left;
      height: 364px;
      position: relative;
      width: 298px;
      padding-left : 2px;
      padding-bottom: 2px;
      overflow: hidden;
    }
    .gcat-item img{
      width: 100%;
      height: 100%;
    }
    .gcat-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 183px;
      line-height: 20px;
      padding-top: 150px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 298px;
      display: none;
    }
    .gcat-item a.gcat-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .gcat-item:hover a.gcat-item-title{
      display: block;
      color:#fff;
    }

    .menu-item{
       float: left;
      height: 212px;
      position: relative;
      width: 139px;
      padding-left : 2px;
      padding-bottom: 2px;
      overflow: hidden;
    }
    .menu-item img{
      width: 100%;
      height: 100%;
    }
    .menu-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 212px;
      line-height: 20px;
      padding-top: 50px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 139px;
      display: none;
    }
    .menu-item a.menu-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .menu-item:hover a.menu-item-title{
      display: block;
      color:#fff;
    }
  </style>
  
  
  <div style="position: absolute;
    right: 580px;
    top: 0px;  ">
    <div class="offcanvas">
      <ul id="menu-vietnamese-menu" class="main-nav">
        <li>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <!-- <a href="/" itemprop="name">
              TRANG CHỦ            
            </a>
            <a class="menu-item-title" href="/" style="height: 141px;">TRANG CHỦ</a> -->
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="DỰ ÁN" itemprop="image">    -->
              DỰ ÁN           
            </a>
            <a class="menu-item-title" href="/products" style="height: 141px;">DỰ ÁN</a>
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="TRANG CHỦ" itemprop="image">   -->
              CHÚNG TÔI
            </a>
            <a class="menu-item-title" href="/introduce" style="height: 141px;">CHÚNG TÔI</a>
          </div>
          <div class="menu-item" style="height: 141px;width: 150px;">
            <a href="/" itemprop="name">
              <!-- <img [src]="" title="DỰ ÁN" itemprop="image">               -->
              NGHỆ THUẬT
            </a>
            <a class="menu-item-title" href="/nghethuat" style="height: 141px;">NGHỆ THUẬT</a>
          </div>
          
        </li>
        <!-- <li id="menu-item-54" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-547"><a
            href="/">TRANG CHỦ</a></li>
        <li id="menu-item-547" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-547"><a
            href="/products">DỰ ÁN</a></li>
        <li id="menu-item-546" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-546"><a
            href="/introduce">CHÚNG TÔI</a></li>					
        <li id="menu-item-548" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-548"><a
            href="/contact">LIÊN HỆ</a></li> -->
      </ul>
    </div>
    <button class="offcanvas-toggle">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
  
  <div class="logo">
    <a href="/"><img src="/assets/Home_files/logo.png" title="Trang chủ"></a>

  </div>
  
  <div class="main-component2">
    <div id="system-message-container">
    </div>

          
        
        
        <div class="gcat-item" style="height: 300px;width: 298px;" *ngFor="let product of products | async | search: searchTerm | sort:['weight']">
          <a routerLink="/category/{{product.slug}}" itemprop="name">
            <img [src]="getProductImage(product)" alt="{{product.title}}" itemprop="image">
            
          </a>
          <a class="gcat-item-title" routerLink="/category/{{product.slug}}" style="height: 300px;"></a>
        </div>
        
                
  

    </div>
  <div class="body-gioithieu">
    
  </div>
  
  <script type="text/javascript">
    (function ($) {
      'use strict';



      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });

    }(jQuery));
  </script>
  



<!-- 
<div class="container">
  <div class="grid">
    <div class="grid-tile" *ngFor="let product of products | async | search: searchTerm | sort:['weight']">
      <mat-card routerLink="/product/{{product.url}}" class="product" itemscope itemtype="http://schema.org/Product">
        <img [src]="getProductImage(product)" alt="{{product.title}}" itemprop="image">
        <div class="product-content">
          <h3>
            <a routerLink="/product/{{product.url}}" itemprop="name">
              {{product.title}}
            </a>
          </h3>
          <meta itemprop="priceCurrency" content="USD" />
          <div class="price" itemprop="price">{{product.price | currency:'USD':true }}</div>
        </div>
      </mat-card>
    </div>  
    <p class="block-center" *ngIf="searchTerm && !(products | async | search: searchTerm)?.length">No products</p>
  </div>
</div> -->